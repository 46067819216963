import React, { FC } from 'react';
import cx from 'classnames';
import { IconButtonProps } from '../IconButton';
import * as SC from './styled';
export type IconsNavProps = {
  className?: string;
  links: IconButtonProps[];
};
const IconsNav: FC<IconsNavProps> = props => {
  const {
    className,
    links
  } = props;
  return links.length > 0 ? <SC.IconsNav className={cx('IconsNav', className)}>
      {links && links.length > 0 && <SC.List>
          {links.map((item, index) => {
        return <SC.Item key={`Social-item-${index}`}>
                <SC.Button {...item} />
              </SC.Item>;
      })}
        </SC.List>}
    </SC.IconsNav> : null;
};
export default IconsNav;