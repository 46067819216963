import React, { FC, ComponentType } from 'react';
import { Field, FieldProps } from 'formik';
export type FormikFieldProps = {
  className?: string;
  name?: string;
  Component: ComponentType<any>;
  onChange?: (e: React.ChangeEvent<any>) => void;
  hideError?: boolean;
  [key: string]: any;
};
const FormikField: FC<FormikFieldProps> = ({
  name,
  Component,
  hideError,
  ...rest
}) => {
  return <Field name={name} {...rest}>
      {({
      field,
      meta
    }: FieldProps) => {
      const fieldError = meta.error;
      const hasError = meta.touched && !!fieldError;
      const onChange = (e: React.ChangeEvent<any>) => {
        field.onChange(e);
        rest?.onChange?.(e);
      };
      return <Component {...rest} {...field} onChange={onChange} hasError={hasError} error={hasError && !hideError ? fieldError : undefined} />;
    }}
    </Field>;
};
export default FormikField;