import React, { FC } from 'react';
import cx from 'classnames';
import { LinkProps } from '../Link';
import { IconProps } from '../Icon';
import * as SC from './styled';
export type SocialsProps = {
  className?: string;
  label?: string;
  linksProps?: {
    linkProps: LinkProps;
    iconProps?: IconProps;
  }[];
};
const Socials: FC<SocialsProps> = props => {
  const {
    className,
    label,
    linksProps
  } = props;
  return linksProps && linksProps.length > 0 ? <SC.Socials className={cx('Socials', className)}>
      {label && <SC.Text>{label}</SC.Text>}
      {linksProps && linksProps.length > 0 && <SC.List>
          {linksProps.map((item, index) => {
        return <SC.Item key={`Social-item-${index}`}>
                <SC.StyledLink {...item.linkProps}>
                  {item.linkProps.label && <SC.Label>{item.linkProps.label}</SC.Label>}
                  {item.iconProps && <SC.StyledIcon {...item.iconProps} />}
                </SC.StyledLink>
              </SC.Item>;
      })}
        </SC.List>}
    </SC.Socials> : null;
};
export default Socials;