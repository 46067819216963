import React, { FC } from 'react';
import { FormFieldInputProps } from '../FormFieldInput';
import { FormSubmitButtonProps } from '../../FormSubmitButton';
import { Icons } from '../../../atoms/Icon';
import * as SC from './styled';
export type FormFieldInputWithSubmitProps = FormFieldInputProps & {
  submitProps?: FormSubmitButtonProps;
};
const FormFieldInputWithSubmit: FC<FormFieldInputWithSubmitProps> = props => {
  const {
    submitProps,
    ...extraProps
  } = props;
  return <SC.Input {...extraProps} rightAdornment={<SC.Submit iconProps={{
    icon: Icons.arrowRight
  }} {...submitProps} />} />;
};
export default FormFieldInputWithSubmit;