import React, { FC } from 'react';
import { LinkProps } from '../../atoms/Link';
import * as SC from './styled';
export type FooterSection = {
  link: LinkProps;
  links: LinkProps[];
};
export type FooterMenuProps = {
  className?: string;
  sections: FooterSection[];
};
const FooterMenu: FC<FooterMenuProps> = ({
  className,
  sections
}) => {
  return <SC.FooterMenu className={className}>
      <SC.Wrapper $length={sections.length}>
        {sections?.map((section, sectionIndex) => <SC.Section key={sectionIndex}>
            <SC.SectionTitle {...section.link} />
            {section.links && <SC.SectionList>
                {section.links?.map((link, linkIndex) => {
            return <li key={linkIndex}>
                      {link.href ? <SC.SectionEntry {...link} /> : <SC.SectionEntryTitle {...link} />}
                    </li>;
          })}
              </SC.SectionList>}
          </SC.Section>)}
      </SC.Wrapper>
    </SC.FooterMenu>;
};
export default FooterMenu;