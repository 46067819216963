const PORT = String(process.env.PORT) !== '80' && String(process.env.PORT)

const APP = {
  APP_ENV:
    process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV || 'production',
  APP_VERSION: String(process.env.APP_VERSION) || '',
  BANNER: String(process.env.BANNER) || false,
  PORT,
  APP_URL: String(process.env.NEXT_PUBLIC_APP_URL),
  APP_PREVIEW_URL: String(process.env.APP_PREVIEW_URL),
  SECURE_COOKIE: ['true', '1'].includes(
    String(process.env.NEXT_PUBLIC_SECURE_COOKIE)
  ),
  COOKIE_DOMAIN: String(process.env.NEXT_PUBLIC_COOKIE_DOMAIN),
  COOKIE_NAME: String(process.env.NEXT_PUBLIC_COOKIE_NAME) || 'auth_token',
  IS_PREVIEW: ['true', '1'].includes(String(process.env.IS_PREVIEW)),
  NO_INDEX: ['true', '1'].includes(String(process.env.NO_INDEX)),
  USE_PWA: ['true', '1'].includes(String(process.env.NEXT_PUBLIC_USE_PWA)),
  IGNORE_API_SSL_ERRORS: ['true', '1'].includes(
    String(process.env.IGNORE_API_SSL_ERRORS)
  ),
  HEALTH_TAG_IDS: ['214'],
  PERSIST_STORE_ENABLED: ['true', '1'].includes(
    String(process.env.NEXT_PUBLIC_PERSIST_STORE_ENABLED)
  ),
  GTM_ID: String(process.env.NEXT_PUBLIC_GTM_ID),
  REDUCTION_SLUG: '/bons-de-reduction',
  BONSPLANS_GAME_SLUG: '/nf/bonsplans-jeux',
  TOASTER_TIMEOUT: 5000,
}

export default APP
