import React, { FC } from 'react';
import Head from 'next/head';
import { BreadcrumbList } from 'schema-dts';
import { jsonLdScriptProps } from 'react-schemaorg';
import { LinkProps } from '../../atoms/Link';
import { Icons } from '../../atoms/Icon';
import * as SC from './styled';
export type BreadcrumbProps = {
  className?: string;
  entries?: LinkProps[];
  jsonLdBasePath?: string;
};
const Breadcrumb: FC<BreadcrumbProps> = props => {
  const {
    className,
    entries,
    jsonLdBasePath
  } = props;
  return <SC.Container className={className}>
      {entries && entries?.length > 0 && <Head>
          <script {...jsonLdScriptProps<BreadcrumbList>({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: entries?.map((entry, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          name: entry?.label ?? '',
          ...(entry?.href ? {
            item: entry?.href?.match(/^http(s*):/) ? entry?.href : jsonLdBasePath + entry?.href
          } : {})
        }))
      })} />
        </Head>}
      {entries?.map((entry, i) => <SC.EntryContainer key={i}>
          <SC.Entry {...entry} />
          {i !== entries?.length - 1 && <SC.Separator icon={Icons.chevronBreadcrumb} />}
        </SC.EntryContainer>)}
    </SC.Container>;
};
export default Breadcrumb;