import styled from 'styled-components'

import Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'
import FormSubmitButton from '../../form/FormSubmitButton'
import FormikForm from '../../form/FormikForm'
import { ErrorMessage } from '../../form/fields/FormFieldInput/styled'
import Icon from '../../atoms/Icon'
import FormikField from '../../form/FormikField'
import ActionButton from '../../atoms/ActionButton'
import { Error } from '../../form/fields/FormFieldCheckbox/styled'

export const Main = styled.aside`
  border-radius: 2rem;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media print {
    display: none;
  }
`

export const BackgroundImage = styled(Picture)`
  position: absolute;
  flex-shrink: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: initial;
    max-height: initial;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #1b2135;
  opacity: 0.6;
`

export const Content = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 3.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2.4rem;
`

export const Title = styled(Html)`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const CheckMark = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.ruby};
  border-radius: 2.4rem;
  padding: 1.4rem;
  width: 6rem;
  height: 6rem;
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Legend = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.pureWhite};
  & a {
    color: ${({ theme }) => theme.colors.pureWhite};
    text-decoration: underline;
  }
`
export const Form = styled(FormikForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  & ${ErrorMessage} {
  }
`
export const Submit = styled(FormSubmitButton)``

export const LegalField = styled(FormikField)`
  & label,
  p {
    color: ${({ theme }) => theme.colors.pureWhite};
    align-items: flex-start;
  }
  & label p {
    ${({ theme }) => theme.textStyles.textXSmall}
  }
  & a {
    color: inherit;
  }
  & svg {
    border: 1px solid ${({ theme }) => theme.colors.pureWhite};
  }
  & ${Error} {
    ${({ theme }) => theme.textStyles.textXSmall}
    align-items: flex-start;
    text-align: start;
  }
`

export const SubmitCta = styled(ActionButton)`
  width: max-content;
`
