import React, { FC } from 'react';
import { IconProps } from '../Icon';
import { LinkProps } from '../Link';
import * as SC from './styled';
import { ActionButtonVariant } from './styled';
export type ActionButtonProps = LinkProps & {
  label?: string;
  subLabel?: string;
  active?: boolean;
  iconProps?: IconProps;
  iconPosition?: 'left' | 'right';
  variant?: ActionButtonVariant;
};
const ActionButton: FC<ActionButtonProps> = ({
  label,
  subLabel,
  text,
  children,
  active,
  iconProps,
  iconPosition,
  variant = ActionButtonVariant.primary,
  ...linkProps
}) => {
  return <SC.ActionButton $active={active} $variant={variant} {...linkProps}>
      {iconProps && (!iconPosition || iconPosition === 'left') && <SC.ActionIcon {...iconProps} />}
      {(children || label || text) && !subLabel && <SC.Label>{children || label || text}</SC.Label>}
      {label && subLabel && !children && !text && <SC.Labels>
          <SC.Label>{children || label || text}</SC.Label>
          <SC.SubLabel>{subLabel}</SC.SubLabel>
        </SC.Labels>}
      {iconProps && iconPosition === 'right' && <SC.ActionIcon {...iconProps} />}
    </SC.ActionButton>;
};
export default ActionButton;