import React, { ChangeEvent, FC, useCallback, useId, useMemo, useState } from 'react';
import Autosuggest, { RenderInputComponentProps } from 'react-autosuggest';
import { FormFieldInputProps } from '../FormFieldInput';
import * as SC from './styled';
export type FormFieldAutoSuggestValue = {
  label: string;
  value?: string;
};
export type FormFieldAutoSuggestProps = Omit<FormFieldInputProps, 'onSubmit'> & {
  suggestions?: (FormFieldAutoSuggestValue | string)[];
  loadSuggestions?: (value: string) => void;
  onSubmit?: (value: FormFieldAutoSuggestValue | string) => void;
  renderSuggestionsOnFocus?: boolean;
};
const FormFieldAutoSuggest: FC<FormFieldAutoSuggestProps> = props => {
  const {
    className,
    suggestions,
    onChange,
    onSubmit,
    name,
    value,
    loadSuggestions,
    renderSuggestionsOnFocus,
    disabled,
    ...extraProps
  } = props;
  const id = useId();
  const [focused, setFocused] = useState(false);
  return <SC.AutoSuggest className={className}>
      <Autosuggest id={id} inputProps={useMemo(() => ({
      value: value as string ?? '',
      onChange: onChange as any,
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false)
    }), [onChange, value])} multiSection={false} suggestions={suggestions ?? []} renderInputComponent={useCallback((p: RenderInputComponentProps) => <SC.Input name={name} {...extraProps} {...p} key={id} theme={undefined} $idDisabled={disabled ?? false} disabled={disabled ?? false} />, [disabled, extraProps, id, name])} renderSuggestion={useCallback((entry: any) => {
      return <div>{typeof entry === 'string' ? entry : entry?.label}</div>;
    }, [])} alwaysRenderSuggestions={focused && renderSuggestionsOnFocus} onSuggestionSelected={useCallback((_event: any, {
      suggestion,
      suggestionValue
    }: {
      suggestion: any;
      suggestionValue: string;
    }) => {
      onChange?.({
        target: {
          name,
          value: suggestionValue
        }
      } as ChangeEvent<any>);
      onSubmit?.(suggestion);
    }, [name, onChange, onSubmit])} onSuggestionsFetchRequested={useCallback(({
      value: inputValue
    }) => loadSuggestions?.(inputValue), [loadSuggestions])} onSuggestionsClearRequested={useCallback(() => loadSuggestions?.(''), [loadSuggestions])} getSuggestionValue={useCallback((entry: any): string => {
      return typeof entry === 'string' ? entry : entry?.label;
    }, [])} focusInputOnSuggestionClick={false} />
    </SC.AutoSuggest>;
};
export default FormFieldAutoSuggest;