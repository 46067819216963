import { useFormikContext } from 'formik';
import { useEffect } from 'react';
export const FormikScrollOnError = () => {
  const {
    submitCount,
    isValid,
    errors
  } = useFormikContext();
  useEffect(() => {
    if (isValid) return;
    const errorKeysArray = Object.keys(errors);
    const element = document.querySelector(`input[name='${errorKeysArray[0]}']`);
    if (!element) return;
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [errors, isValid, submitCount]);
  return null;
};