import React, { FC } from 'react';
import { Icons } from '../../atoms/Icon';
import * as SC from './styled';
export type FormMessage = {
  label?: string;
};
export type FormMessagesProps = {
  className?: string;
  isError?: boolean;
  messages?: FormMessage[] | null;
  isHtml?: boolean;
};
const FormMessages: FC<FormMessagesProps> = props => {
  const {
    className,
    isError = true,
    messages,
    isHtml
  } = props;
  return messages && messages.length > 0 ? <SC.Messages className={className} $isError={isError}>
      <SC.StyledIcon icon={isError === true ? Icons.close : Icons.check} width={24} height={24} />
      {messages?.map((message, i) => isHtml ? <SC.Message key={i} dangerouslySetInnerHTML={{
      __html: message.label || ''
    }} /> : <SC.Message key={i}>{message.label}</SC.Message>)}
    </SC.Messages> : null;
};
export default FormMessages;