import React, { FC } from 'react';
import cx from 'classnames';
import { Icons } from '../Icon';
import { ToasterType } from './styled';
import * as SC from './styled';
export type StoreToaster = {
  text: string;
  variant?: ToasterType;
};
export type ToasterProps = {
  className?: string;
  text: string;
  variant?: ToasterType;
  open?: boolean;
};
const icons = {
  [ToasterType.info]: Icons.info,
  [ToasterType.success]: Icons.check,
  [ToasterType.error]: Icons.close
};
const Toaster: FC<ToasterProps> = props => {
  const {
    className,
    text,
    variant,
    open
  } = props;
  const icon = variant ? icons?.[variant] : undefined;
  return <SC.Container className={cx('Toaster', className)} $variant={variant} $open={open || false}>
      {icon && <SC.StyledIcon icon={icon} />}
      {text && <SC.Text>{text}</SC.Text>}
    </SC.Container>;
};
export default Toaster;