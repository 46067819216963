import React, { FC } from 'react';
import { RegisterPushProps } from '../RegisterPush';
import { NewsletterPushProps } from '../NewsletterPush';
import * as SC from './styled';
export type FooterRegisterProps = (RegisterPushProps | NewsletterPushProps) & {
  isNewsletter: boolean;
};
const FooterRegister: FC<FooterRegisterProps> = ({
  ...registerProps
}) => {
  if (registerProps.isNewsletter) {
    return <SC.NewsletterPush {...registerProps as NewsletterPushProps} />;
  }
  return <SC.Register {...registerProps as RegisterPushProps} />;
};
export default FooterRegister;