import React from 'react';
import { Formik } from 'formik';
import { FormikConfig, FormikProps } from 'formik/dist/types';
import * as SC from './styled';
import FormikContextObserver, { FormikContextObserverProps } from './FormikContextObserver';
import { FormikScrollOnError } from './FormikScrollOnError';
export type FormikFormProps<Values = any> = FormikConfig<Values> & {
  className?: string;
  onContextUpdate?: FormikContextObserverProps<Values>['onUpdate'];
  scrollOnError?: boolean;
  children?: ((props: FormikProps<Values>) => React.ReactNode) | React.ReactNode;
};
const FormikForm: React.FC<FormikFormProps> = ({
  className,
  onContextUpdate,
  scrollOnError = true,
  children,
  ...props
}) => {
  return <Formik validateOnChange={false} validateOnBlur={false} {...props}>
      {formikProps => <>
          {onContextUpdate && <FormikContextObserver onUpdate={onContextUpdate} />}
          <SC.Form className={className} noValidate onSubmit={formikProps.handleSubmit} method="POST">
            {typeof children === 'function' ? children(formikProps) : children}
          </SC.Form>
          {scrollOnError && <FormikScrollOnError />}
        </>}
    </Formik>;
};
export default FormikForm;