import React, { FC, memo } from 'react';
import * as icons from './icons';
import * as SC from './styled';
import { IconProps } from './types';
export * from './types';
const Icon: FC<IconProps> = ({
  className,
  icon,
  color,
  width,
  height,
  preserveAspectRatio,
  onClick
}) => {
  const {
    viewBox,
    id,
    url
  } = icons[icon];
  const symbolId = url ?? '#' + id?.replace('-usage', '') ?? '';
  return <SC.Svg className={className} $color={color} viewBox={viewBox} {...color && {
    color
  }} {...width && {
    width
  }} {...height && {
    height
  }} {...preserveAspectRatio && {
    preserveAspectRatio
  }} {...onClick ? {
    onClick
  } : {
    'aria-hidden': true
  }} {...!onClick && {
    onClick
  }}>
      <use xlinkHref={symbolId} href={symbolId} />
    </SC.Svg>;
};
export default memo(Icon);