import React, { AriaAttributes, FC } from 'react';
import cx from 'classnames';
import { LinkProps } from '../../atoms/Link';
import Icon, { Icons } from '../../atoms/Icon';
import { NavCardProps } from '../NavCard';
import * as SC from './styled';
export type SubNavMenu = {
  title?: string;
  links?: LinkProps[];
  bottomLink?: LinkProps;
};
export type NavMenu = {
  content?: SubNavMenu[];
  bottomLink?: LinkProps;
};
export type NavCards = {
  content?: NavCardProps[];
  bottomLink?: LinkProps;
};
export type SubNavProps = AriaAttributes & {
  className?: string;
  id?: string;
  role?: string;
  menus?: NavMenu;
  cards?: NavCards;
  open?: boolean;
};
const SubNav: FC<SubNavProps> = props => {
  const {
    className,
    role,
    menus,
    cards,
    open,
    ...rest
  } = props;
  return <SC.SubNav className={cx('SubNav', className)} role={role} {...rest}>
      <SC.Wrapper>
        {!!menus && <SC.Section>
            {!!menus.content && menus.content.length > 0 && menus.content.map((menu, index) => <SC.Menu key={index}>
                  {menu.title && <SC.MenuTitle>{menu.title}</SC.MenuTitle>}
                  {menu.links && menu.links.length > 0 && <SC.Nav $length={menu.links.length} $oneMenuOf={menus.content?.length} $oneColumnOf={(menus.content?.length ?? 0) + (cards?.content?.length ?? 0)}>
                      {menu.links.map((link, i) => <li key={`${index}-${i}`}>
                          <SC.NavLink {...link} />
                        </li>)}
                    </SC.Nav>}
                  {menu.bottomLink && <SC.MenuLink {...menu.bottomLink}>
                      {menu.bottomLink.label} <Icon icon={Icons.arrowRight} />
                    </SC.MenuLink>}
                </SC.Menu>)}
            {!!menus.bottomLink && <SC.BottomLink {...menus.bottomLink}>
                {menus.bottomLink.label} <Icon icon={Icons.arrowRight} />
              </SC.BottomLink>}
          </SC.Section>}
        {!!cards && <SC.Section $isCards>
            {!!cards.content && cards.content.length > 0 && cards.content.map((card, index) => <SC.Card {...card} key={index} $oneCardOf={cards.content?.length} $oneColumnOf={(menus?.content?.length ?? 0) + (cards.content?.length ?? 0)} imageProps={open ? card?.imageProps : undefined} />)}
            {!!cards.bottomLink && <SC.BottomLink {...cards.bottomLink}>
                {cards.bottomLink.label} <Icon icon={Icons.arrowRight} />
              </SC.BottomLink>}
          </SC.Section>}
      </SC.Wrapper>
    </SC.SubNav>;
};
export default SubNav;