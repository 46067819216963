import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useLockedBody } from 'usehooks-ts';
import { useRouter } from 'next/router';
import { LinkProps } from '../../atoms/Link';
import Icon, { Icons } from '../../atoms/Icon';
import { NavCards, NavMenu } from '../SubNav';
import { NavCardProps } from '../NavCard';
import * as SC from './styled';
export type BurgerNavProps = {
  className?: string;
  id: string;
  navigation?: {
    link?: LinkProps;
    menus?: NavMenu;
    cards?: NavCards;
  }[];
  highlightCards?: NavCardProps[];
  isOpen?: boolean;
  navHandler?: () => void;
  texts: {
    open: string;
    close: string;
    back?: string;
  };
};
const BurgerNav: FC<BurgerNavProps> = props => {
  const {
    className,
    id,
    navigation,
    highlightCards,
    isOpen = false,
    texts
  } = props;
  const [stateOpen, setStateOpen] = useState(false);
  const [openLevel1, setOpenLevel1] = useState(-1);
  const refNavigation = useRef<HTMLDivElement | null>(null);
  const refBurger = useRef<HTMLButtonElement | null>(null);
  const refSubpanels = useRef<HTMLDivElement[]>([]);
  const router = useRouter();
  const handleClickToggle = useCallback((_event: React.MouseEvent, open?: boolean) => {
    setStateOpen(open ? open : !stateOpen);
    !open && setOpenLevel1(-1);
  }, [stateOpen]);
  useEffect(() => {
    setStateOpen(isOpen);
  }, [isOpen]);
  const focusContentOnOpenSubmenu = useCallback((index: number) => {
    const focusableEls = refSubpanels?.current[index]?.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
    const firstEl = Array.prototype.slice.call(focusableEls)[0];
    firstEl?.focus({
      focusVisible: true
    });
  }, []);

  // focus in / out submenu when navigating
  useEffect(() => {
    if (openLevel1 >= 0) {
      focusContentOnOpenSubmenu(openLevel1);
    } else {
      refNavigation.current?.focus();
    }
  }, [focusContentOnOpenSubmenu, openLevel1]);

  // focus in and out of the burger menu
  useEffect(() => {
    if (stateOpen) {
      refNavigation.current?.focus();
      refBurger.current?.blur();
    } else {
      refNavigation.current?.blur();
      refBurger.current?.focus();
    }
  }, [stateOpen]);

  // lock body scroll when open
  const [, setLocked] = useLockedBody();
  useEffect(() => {
    setLocked(stateOpen ?? false);
  }, [stateOpen, setLocked]);

  // close the popin on "escape"
  useEffect(() => {
    if (stateOpen === false) return;
    const handlePressEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setStateOpen(false);
        setOpenLevel1(-1);
      }
    };
    window.addEventListener('keydown', handlePressEscape);
    return () => {
      window.removeEventListener('keydown', handlePressEscape);
    };
  }, [stateOpen]);

  // close the nav on route change
  useEffect(() => {
    if (!router) return;
    const close = () => {
      setStateOpen(false);
      setOpenLevel1(-1);
    };
    router?.events.on('routeChangeStart', close);
    return () => {
      router?.events.off('routeChangeStart', close);
    };
  }, [router]);
  const renderNav = useMemo(() => navigation?.map((item, index) => {
    const itemMenus = item.menus?.content;
    const itemCards = item.cards?.content;
    const hasContent: boolean = !!itemMenus?.length || !!itemCards?.length;
    return <li key={index}>
            <SC.Lvl1Link {...item.link} {...hasContent && {
        href: undefined,
        onClick: () => setOpenLevel1(index)
      }}>
              <span>{item.link?.label}</span>
              {hasContent && <Icon icon={Icons.chevronRight} width={16} height={16} />}
            </SC.Lvl1Link>
          </li>;
  }), [navigation]);
  const renderNav2 = useMemo(() => navigation?.map((item, index) => {
    const itemMenus = item.menus?.content;
    const itemCards = item.cards?.content;
    const hasContent: boolean = !!itemMenus?.length || !!itemCards?.length;
    return hasContent ? <SC.Panel key={index} aria-hidden={openLevel1 !== index} ref={(el: HTMLDivElement) => {
      if (el) refSubpanels.current[index] = el;
    }}>
            <SC.Label>
              <SC.Back onClick={() => setOpenLevel1(-1)} {...texts.back ? {
          'aria-label': texts.back
        } : {}}>
                <Icon icon={Icons.chevronLeft} width={24} height={24} />
              </SC.Back>
              <SC.LabelLink {...item?.link}>
                <span>{item?.link?.label}</span>
                <Icon icon={Icons.arrowRight} width={24} height={24} />
              </SC.LabelLink>
            </SC.Label>
            {!!itemMenus?.length && itemMenus.map((lvl2menu, i) => <React.Fragment key={`${index}-${i}`}>
                  {lvl2menu.title && <SC.Lvl2Subtitle>{lvl2menu.title}</SC.Lvl2Subtitle>}
                  <SC.Lvl2Menu>
                    {lvl2menu.links && lvl2menu.links.length > 0 && lvl2menu.links.map((lvl2item, x) => <li key={`${index}-${i}-${x}`}>
                          <SC.Lvl2Link {...lvl2item} />
                        </li>)}
                  </SC.Lvl2Menu>
                  {lvl2menu.bottomLink && <SC.Lvl2BottomLink {...lvl2menu.bottomLink}>
                      {lvl2menu.bottomLink.label && <span>{lvl2menu.bottomLink.label}</span>}
                      <Icon icon={Icons.arrowRight} width={24} height={24} />
                    </SC.Lvl2BottomLink>}
                </React.Fragment>)}
            {item.menus?.bottomLink && <SC.Lvl1BottomLink {...item.menus.bottomLink}>
                {item.menus.bottomLink.label && <span>{item.menus.bottomLink.label}</span>}
                <Icon icon={Icons.arrowRight} width={24} height={24} />
              </SC.Lvl1BottomLink>}
            {!!itemCards?.length && <SC.Cards>
                {itemCards.map((card, i) => <SC.Card {...card} key={`${index}-${i}`} imageProps={stateOpen ? card?.imageProps : undefined} />)}
              </SC.Cards>}
            {item.cards?.bottomLink && <SC.Lvl1BottomLink {...item.cards.bottomLink}>
                {item.cards.bottomLink.label && <span>{item.cards.bottomLink.label}</span>}
                <Icon icon={Icons.arrowRight} width={24} height={24} />
              </SC.Lvl1BottomLink>}
          </SC.Panel> : null;
  }), [navigation, openLevel1, stateOpen, texts.back]);
  return navigation && navigation.length > 0 ? <SC.BurgerNav className={cx('BurgerNav', className)}>
      <SC.Button aria-expanded={stateOpen} aria-controls={id} aria-label={stateOpen ? texts.close : texts.open} onClick={handleClickToggle} ref={refBurger}>
        {stateOpen ? <SC.BurgerIcon icon={Icons.close} width={24} height={24} /> : <SC.BurgerIcon icon={Icons.burger} width={24} height={24} />}
      </SC.Button>
      <SC.Navigation id={id} aria-hidden={!stateOpen} ref={refNavigation} tabIndex={stateOpen ? 0 : -1}>
        <SC.Panels $toLevel2={openLevel1 !== -1}>
          <SC.Panel>
            <SC.Lvl1Menu>
              {renderNav}
              {highlightCards && highlightCards.length > 0 && highlightCards.map((highlightCard, index) => <li className="is-highlight" key={index}>
                    <SC.HighlightCard {...highlightCard} />
                  </li>)}
            </SC.Lvl1Menu>
          </SC.Panel>
          {renderNav2}
        </SC.Panels>
      </SC.Navigation>
    </SC.BurgerNav> : null;
};
export default BurgerNav;